<template>
  <b-form
    id="beef-add-form"
    class="p-2"
    @submit.prevent="handleSubmit(onSubmit)"
    @reset.prevent="resetForm"
  >
    <!-- rationname -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="rationname"
    >
      <b-form-group :label="$t('general')" class="p-1 border">
        <label for="rationname">
          <span class="text-danger">*</span>
          {{ $t("rationName") }}
        </label>
        <b-form-input
          id="rationname"
          v-model="blankRationData.RasyonAdi"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- breed -->

    <b-form-group
      id="animal-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('animalInfo')"
    >
      <validation-provider #default="validationContext" name="breed">
        <label for="breed">
          <span class="text-danger">*</span>
          {{ $t("breed") }}
        </label>

        <v-select
          id="breed"
          v-model="blankRationData.irkid"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="animalTypes"
          class="w-100"
          :reduce="(val) => val.value"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- ca -->
      <validation-provider #default="validationContext" name="ca">
        <label for="ca">
          <span class="text-danger">*</span>
          {{ $t("ca") }}
        </label>
        <b-form-input
          id="ca"
          v-model="blankRationData.CA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- gcaa -->
      <validation-provider #default="validationContext" name="gcaa">
        <label for="gcaa">
          <span class="text-danger">*</span>
          {{ $t("ga") }}
        </label>
        <b-form-input
          id="gcaa"
          v-model="blankRationData.GCAA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- sp -->
      <validation-provider #default="validationContext" name="sp">
        <label for="sp">
          <span class="text-danger">*</span>
          {{ $t("sp") }}
        </label>
        <b-form-input
          id="sp"
          v-model="blankRationData.SP"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="ration-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('rationInfo')"
    >
      <validation-provider #default="validationContext" name="applicationDay">
        <label for="applicationDay">
          <span class="text-danger">*</span>
          {{ $t("numberofDaystoApply") }}
        </label>
        <v-select
          id="applicationDay"
          v-model="blankRationData.SV"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="[15, 30]"
          class="w-100"
          :reduce="(val) => val"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <span
        class="d-flex h-100 align-items-center justify-content-lg-center pt-1"
      >
        <label> Hes.Esas.Ca: {{ realCa }}</label>
      </span>
    </b-form-group>
    <b-form-group
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('environmentalInfo')"
    >
      <!-- sicaklik -->
      <validation-provider #default="validationContext" name="sicaklik">
        <label for="sicaklik">
          <span class="text-danger">*</span>
          {{ $t("temperature") }}
        </label>
        <b-form-input
          id="GY"
          v-model="blankRationData.sicaklik"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- sy -->
      <validation-provider #default="validationContext" name="gy">
        <label for="gy">
          <span class="text-danger">*</span>
          {{ $t("gy") }}
        </label>
        <b-form-input
          id="gy"
          v-model="blankRationData.SY"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('roughage') + ' / ' + $t('grain') + ' ' + $t('rate') + ' (%)'"
    >
      <validation-provider #default="validationContext" name="minkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('minKaba')"
          label-for="minkaba"
        >
          <v-select
            id="minkaba"
            v-model="blankRationData.kabayemorani"
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="oneToHundred"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-form-group>
      </validation-provider>

      <validation-provider #default="validationContext" name="maxkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('maxKaba')"
          label-for="maxkaba"
        >
          <v-select
            id="maxkaba"
            v-model="blankRationData.kabayemoranimax"
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="oneToHundred"
            class="w-100"
            :reduce="(val) => val.value"
          />
        </b-form-group>
      </validation-provider>
    </b-form-group>
  </b-form>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import dayjs from "dayjs";
import { getUserData } from "@/auth/utils";

//every vs map
//

export default {
  components: {
    BForm,
    BFormInput,
    vSelect,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    formValidation: {
      type: Function,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      oneToFive: Array.from(Array(5), (_, i) => i + 1).map((val) => {
        return { label: val, value: val };
      }),
      oneToHundred: Array.from(Array(100), (_, i) => i + 1).map((val) => {
        return { label: `${val} / ${100 - val}`, value: val };
      }),
      blankRationData: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        kabayemorani: "",
        kabayemoranimax: "",
        RasyonAdi: "",
        irk: "",
        irkid: "",
        CA: "",
        GCAA: "",
        sicaklik: "",
        yemtoplami: 0,
        yogunhammaddelertoplami: 0,
        RasyonFiyati: 0,
        SY: "", // Günlük YAĞIŞ
        SP: "", // Tuz
        SV: "", // 15 || 30
      },

      resetblankration: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        companyid: getUserData().companyid,

        kabayemorani: "",
        kabayemoranimax: "",
        RasyonAdi: "",
        irk: "",
        irkid: "",
        CA: "",
        GCAA: "",
        sicaklik: "",
        yemtoplami: 0,
        yogunhammaddelertoplami: 0,
        RasyonFiyati: 0,
        SY: "", // Günlük YAĞIŞ

        SP: "", // Tuz
        SV: 15, // 15 || 30
      },
    };
  },

  computed: {
    realCa() {
      const { SV, CA, GCAA } = this.blankRationData;
      var calculation = ((SV * GCAA) / 1000 + CA * 2) / 2;
      return calculation;
    },
  },
  watch: {
    blankRationData: {
      handler: function (newVal, oldVal) {
        const newmin = Number(this.blankRationData.kabayemorani);
        const newmax = Number(this.blankRationData.kabayemoranimax);

        if (newmin > newmax) {
          this.blankRationData.kabayemoranimax = newmin;
        }
        if (newmin >= 100) {
          this.blankRationData.kabayemorani = 100;
        }
        if (newmax >= 100) {
          this.blankRationData.kabayemoranimax = "100";
        }
        if (newmin < 0) {
          this.blankRationData.kabayemorani = 0;
        }
        if (newmax < 0) {
          this.blankRationData.kabayemoranimax = 0;
        }
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.blankRationData);
    },
  },
};
</script>

<style lang="scss">
.form-group > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  margin-bottom: 0.5rem;
  width: 100%;
  gap: 14px;
  flex-wrap: wrap;
  span {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 40%;
      max-width: 50%;
    }
  }
}

#beef-add-form {
  .form-group {
    margin-bottom: 0.5rem;
    border-radius: 6px;
    legend {
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
