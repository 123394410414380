<template>
  <BModal
    id="add-new-ration-modal"
    centered
    :visible="isAddNewRationModalActive"
    bg-variant="white"
    shadow
    backdrop
    no-header
    size="lg"
    :ok-title="$t('ok')"
    :title="$t('addRation')"
    ok-only
    @ok="$refs.addForm.onSubmit()"
    @hidden="formValidation().resetForm"
    @change="(val) => change(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->

      <b-form-group
        class="pb-0"
        :label="$t('rationType')"
        label-for="rationTypeSelector"
      >
        <v-select
          v-if="selectedRationType"
          id="rationTypeSelector"
          v-model="selectedRationType"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="rationTypes"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-form-group>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankration
    ).refFormObserver"
      >
        <component
          :is="selectedRationType.rationComponent"
          ref="addForm"
          :handle-submit="handleSubmit"
          :hide="hide"
          :form-validation="formValidation"
          :animal-types="animalType"
          @onSubmit="onSubmit"
        />
        <!-- Form -->
      </validation-observer>
    </template>
  </BModal>
</template>

<script>
import { BFormGroup, BModal } from "bootstrap-vue";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { ValidationObserver } from "vee-validate";

import DairyInra from "../RationAddForm/DairyInra.vue";
import BeefInra from "../RationAddForm/BeefInra.vue";
import DairyNasem from "../RationAddForm/DairyNasem.vue";
import BeefNasem from "../RationAddForm/BeefNasem.vue";
export default {
  components: {
    BModal,
    BFormGroup,
    ValidationObserver,
    vSelect,

    DairyInra,
    BeefInra,
    DairyNasem,
    BeefNasem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewRationModalActive",
    event: "update:is-add-new-ration-modal-active",
  },
  props: {
    isAddNewRationModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRationType: { label: "Select Ration Type" },
      rationTypes: [],
      beefRationTypes: [
        {
          label: "Beef NASEM",
          value: {
            rationComponent: "BeefNasem",
            rationPostMethod: "rationsModule/postBeefNasem",
            rationModel: "NRC ME",
            rationType: "Beef",
          },
        },
        // {
        //   label: "Beef INRA",
        //   value: {
        //     rationComponent: "BeefInra",
        //     rationPostMethod: "rationsModule/postBeefINRA",
        //     rationModel: "INRA",
        //     rationType: "Beef",
        //   },
        // },
      ],
      dairyRationTypes: [
        {
          label: "Dairy Nasem",
          value: {
            rationComponent: "DairyNasem",
            rationPostMethod: "rationsModule/postDairyNasem",
            rationModel: "Nasem",
            rationType: "Dairy",
          },
        },
        // {
        //   label: "Dairy INRA",
        //   value: {
        //     rationComponent: "DairyInra",
        //     rationPostMethod: "rationsModule/postDairyINRA",
        //     rationModel: "INRA",
        //     rationType: "Dairy",
        //   },
        // },
      ],
      animalType: [
        { label: "Holstein (Siyah Alaca)", value: "Holstein (Siyah Alaca)" },
        { label: "Ayrshire", value: "Ayrshire" },
        { label: "Montofon", value: "Montofon" },
        { label: "Gernzey", value: "Gernzey" },
        { label: "Şorthorn", value: "Şorthorn" },
        { label: "Jersey", value: "Jersey" },
        { label: "Simentel", value: "Simentel" },
      ],
      required,
      blankRationData: {},
      oran: true,
      formValidation: formValidation,
      products: [],
    };
  },

  watch: {
    $route(to, from) {
      this.initValues(to);
    },
  },
  async mounted() {
    this.initValues(this.$route);
    var animalTypes = await this.$http.get("/Animals/GetAnimalBreeds");
    this.animalType = animalTypes.data.map((x) => {
      return { label: x.name, value: x.id };
    });
  },
  methods: {
    initValues(to) {
      // Yunus emre çağlıyan
      // yunus emre çağlıyan
      this.rationTypes =
        to.params.tur == "dairy" ? this.dairyRationTypes : this.beefRationTypes;
      this.selectedRationType =
        to.params.tur == "beef"
          ? {
              rationComponent: "BeefNasem",
              rationPostMethod: "rationsModule/postBeefNasem",
              rationModel: "NRC ME",
              rationType: "Beef",
            }
          : {
              rationComponent: "DairyNasem",
              rationPostMethod: "rationsModule/postDairyNasem",
              rationModel: "Nasem",
              rationType: "Dairy",
            };
    },
    onSubmit(value) {
      value.model = this.selectedRationType.rationModel;
      value.tip = this.selectedRationType.rationType;
      store
        .dispatch(this.selectedRationType.rationPostMethod, {
          ration: value,
          plantid: this.$store.state.app.selectedPlantId,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-ration-modal-active", false);
        });
    },
    change(val) {
      this.$emit("update:is-add-new-ration-modal-active", val);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-ration-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
