var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"p-2",attrs:{"id":"beef-add-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"rationname"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"p-1 border",attrs:{"label":_vm.$t('general')}},[_c('label',{attrs:{"for":"rationname"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("rationName"))+" ")]),_c('b-form-input',{attrs:{"id":"rationname","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.RasyonAdi),callback:function ($$v) {_vm.$set(_vm.blankRationData, "RasyonAdi", $$v)},expression:"blankRationData.RasyonAdi"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}])}),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"animal-info","label":_vm.$t('animalInfo')}},[_c('validation-provider',{attrs:{"name":"breed"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"breed"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("breed"))+" ")]),_c('v-select',{staticClass:"w-100",attrs:{"id":"breed","transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.animalTypes,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankRationData.irkid),callback:function ($$v) {_vm.$set(_vm.blankRationData, "irkid", $$v)},expression:"blankRationData.irkid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"ca"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"ca"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("ca"))+" ")]),_c('b-form-input',{attrs:{"id":"ca","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.CA),callback:function ($$v) {_vm.$set(_vm.blankRationData, "CA", $$v)},expression:"blankRationData.CA"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"gcaa"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"gcaa"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("ga"))+" ")]),_c('b-form-input',{attrs:{"id":"gcaa","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.GCAA),callback:function ($$v) {_vm.$set(_vm.blankRationData, "GCAA", $$v)},expression:"blankRationData.GCAA"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"sp"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"sp"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("sp"))+" ")]),_c('b-form-input',{attrs:{"id":"sp","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.SP),callback:function ($$v) {_vm.$set(_vm.blankRationData, "SP", $$v)},expression:"blankRationData.SP"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])})],1),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"ration-info","label":_vm.$t('rationInfo')}},[_c('validation-provider',{attrs:{"name":"applicationDay"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"applicationDay"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("numberofDaystoApply"))+" ")]),_c('v-select',{staticClass:"w-100",attrs:{"id":"applicationDay","transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[15, 30],"reduce":function (val) { return val; }},model:{value:(_vm.blankRationData.SV),callback:function ($$v) {_vm.$set(_vm.blankRationData, "SV", $$v)},expression:"blankRationData.SV"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('span',{staticClass:"d-flex h-100 align-items-center justify-content-lg-center pt-1"},[_c('label',[_vm._v(" Hes.Esas.Ca: "+_vm._s(_vm.realCa))])])],1),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"environmental-info","label":_vm.$t('environmentalInfo')}},[_c('validation-provider',{attrs:{"name":"sicaklik"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"sicaklik"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("temperature"))+" ")]),_c('b-form-input',{attrs:{"id":"GY","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.sicaklik),callback:function ($$v) {_vm.$set(_vm.blankRationData, "sicaklik", $$v)},expression:"blankRationData.sicaklik"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])}),_c('validation-provider',{attrs:{"name":"gy"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',{attrs:{"for":"gy"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("gy"))+" ")]),_c('b-form-input',{attrs:{"id":"gy","state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"trim":"","placeholder":""},model:{value:(_vm.blankRationData.SY),callback:function ($$v) {_vm.$set(_vm.blankRationData, "SY", $$v)},expression:"blankRationData.SY"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}])})],1),_c('b-form-group',{staticClass:"p-1 border d-flex flex-sm-row flex-md-col",attrs:{"id":"environmental-info","label":_vm.$t('roughage') + ' / ' + _vm.$t('grain') + ' ' + _vm.$t('rate') + ' (%)'}},[_c('validation-provider',{attrs:{"name":"minkaba"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"label":_vm.$t('minKaba'),"label-for":"minkaba"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"minkaba","transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.oneToHundred,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankRationData.kabayemorani),callback:function ($$v) {_vm.$set(_vm.blankRationData, "kabayemorani", $$v)},expression:"blankRationData.kabayemorani"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"maxkaba"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"state":_vm.formValidation(_vm.resetblankration).getValidationState(
            validationContext
          ),"label":_vm.$t('maxKaba'),"label-for":"maxkaba"}},[_c('v-select',{staticClass:"w-100",attrs:{"id":"maxkaba","transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.oneToHundred,"reduce":function (val) { return val.value; }},model:{value:(_vm.blankRationData.kabayemoranimax),callback:function ($$v) {_vm.$set(_vm.blankRationData, "kabayemoranimax", $$v)},expression:"blankRationData.kabayemoranimax"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }