<template>
  <div>
    <ration-list-add-new
      :is-add-new-ration-modal-active.sync="isAddNewRationModalActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewRationModalActive = !isAddNewRationModalActive"
              >
                <span class="text-nowrap">{{ $t("addRation") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refRationListTable"
        class="position-relative"
        :items="fetchRations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Ration -->
        <template #cell(rationname)="data">
          <b-link
            :to="{
              name: 'rationView',
              params: {
                rationid: data.item.id,
                id: $store.state.app.selectedPlantId,
                tur: $route.params.tur,
              },
            }"
          >
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.rasyonadi }}
            </span>
          </b-link>
        </template>
        <template #cell(preview)="data">
          <b-link
            :to="{
              name: 'rationView',
              params: {
                rationid: data.item.id,
                id: $store.state.app.selectedPlantId,
                tur: $route.params.tur,
              },
            }"
          >
            <feather-icon icon="EyeIcon" />
            <small class="align-middle ml-50 text-dark">{{ $t("view") }}</small>
          </b-link>
        </template>
        <template #cell(delete)="data">
          <b-link
            class="text-danger cursor-pointer"
            @click="
              deleteRation({
                id: data.item.id,
                plantid: $route.params.id,
                tur: $route.params.tur,
              })
            "
          >
            <feather-icon icon="TrashIcon" />
            <small class="align-middle ml-50 text-dark">{{
              $t("delete")
            }}</small>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }}</span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useRationList from "./useRationList";
import RationListAddNew from "./RationListAddNew.vue";
import VueI18n from "@/libs/i18n";

import vSelect from "vue-select";

export default {
  components: {
    RationListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },

  beforeRouteEnter(to, from, next) {
    if (to.name == "plantRations") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t("plants"),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("plant"),
        to: `/plant/${to.params.id}`,
        active: true,
      });
      to.meta.breadcrumb.push({
        text:
          VueI18n.t(to.params.tur.toLowerCase()) + " " + VueI18n.t("rations"),
        active: true,
      });
    }
    next();
  },
  watch: {
    $route() {
      this.refetchData();
    },
  },

  setup({ emit }) {
    const isAddNewRationModalActive = ref(false);
    const isEditRationSidebarActive = ref(false);
    const selectedRation = ref({});
    const openEditSideBar = (item) => {
      selectedRation.value = item;
      isEditRationSidebarActive.value = !isEditRationSidebarActive.value;
    };

    const {
      fetchRations,
      tableColumns,
      perPage,
      currentPage,
      totalRations,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRationListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteRation,
    } = useRationList();

    return {
      // Sidebar
      isAddNewRationModalActive,
      isEditRationSidebarActive,
      openEditSideBar,

      selectedRation,
      fetchRations,
      tableColumns,
      perPage,
      currentPage,
      totalRations,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRationListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteRation,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
