<template>
  <b-form
    id="dairy-add-form"
    class=""
    @submit.prevent="handleSubmit(onSubmit)"
    @reset.prevent="resetForm"
  >
    <!-- rationname -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="rationname"
    >
      <!-- yunus emre çağlıyan  -->
      <b-form-group :label="$t('general')" class="p-1 border">
        <label for="rationname">
          <span class="text-danger">*</span>
          {{ $t("rationName") }}
        </label>
        <b-form-input
          id="rationname"
          v-model="blankRationData.RasyonAdi"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- status -->

    <b-form-group
      id="animal-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('animalInfo')"
    >
      <validation-provider #default="validationContext" name="status">
        <label for="status">
          <span class="text-danger">*</span>
          {{ $t("status") }}
        </label>
        <v-select
          id="status"
          v-model="blankRationData.HayvanTipiid"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="animalStatuses"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <validation-provider #default="validationContext" name="breed">
        <label for="breed">
          <span class="text-danger">*</span>
          {{ $t("breed") }}
        </label>

        <v-select
          id="breed"
          v-model="blankRationData.irkid"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="animalTypes"
          class="w-100"
          :reduce="(val) => val.value"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- ca -->
      <validation-provider #default="validationContext" name="ca">
        <label for="ca">
          <span class="text-danger">*</span>
          {{ $t("ca") }}
        </label>
        <b-form-input
          id="ca"
          v-model="blankRationData.CA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- gcaa -->
      <validation-provider #default="validationContext" name="gcaa">
        <label for="gcaa">
          <span class="text-danger">*</span>
          {{ $t("gcaa") }}
        </label>
        <b-form-input
          id="gcaa"
          v-model="blankRationData.GCAA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- ls -->
      <validation-provider
        v-if="showField(5, 6)"
        #default="validationContext"
        name="ls"
      >
        <label for="ls">
          <span class="text-danger">*</span>
          {{ $t("ls") }}
        </label>
        <v-select
          id="oneToFive"
          v-model="blankRationData.LS"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="oneToFive"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <!-- bcs -->
      <validation-provider
        v-if="showField(5, 6)"
        #default="validationContext"
        name="bcs"
      >
        <label for="bcs">
          <span class="text-danger">*</span>
          {{ $t("bcs") }}
        </label>
        <v-select
          id="oneToFive"
          v-model="blankRationData.BCS"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="oneToFiveFloatHalf"
          class="w-100"
          :reduce="(val) => val.value"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- bda -->
      <validation-provider
        v-if="showField(4, 5, 6)"
        #default="validationContext"
        name="bda"
      >
        <label for="bda">
          <span class="text-danger">*</span>
          {{ $t("bda") }}
        </label>
        <b-form-input
          id="bda"
          v-model="blankRationData.BDA"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- kgs -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="kgs"
      >
        <label for="kgs">
          <span class="text-danger">*</span>
          {{ $t("kgs") }}
        </label>
        <b-form-input
          id="kgs"
          v-model="blankRationData.KGS"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- kgg -->
      <validation-provider
        v-if="showField(4, 5, 6)"
        #default="validationContext"
        name="kgg"
      >
        <label for="kgg">
          <span class="text-danger">*</span>
          {{ $t("kgg") }}
        </label>
        <b-form-input
          id="kgg"
          v-model="blankRationData.KGG"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('environmentalInfo')"
    >
      <!-- sicaklik -->
      <validation-provider #default="validationContext" name="sicaklik">
        <label for="sicaklik">
          <span class="text-danger">*</span>
          {{ $t("temperature") }}
        </label>
        <b-form-input
          id="GY"
          v-model="blankRationData.sicaklik"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- MU -->
      <validation-provider #default="validationContext" name="mu">
        <label for="mu">
          <span class="text-danger">*</span>
          {{ $t("mu") }}
        </label>
        <b-form-input
          id="mu"
          v-model="blankRationData.MU"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- mggs -->
      <validation-provider #default="validationContext" name="mggs">
        <label for="mggs">
          <span class="text-danger">*</span>
          {{ $t("mggs") }}
        </label>
        <b-form-input
          id="mggs"
          v-model="blankRationData.MGGS"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- mye -->
      <validation-provider #default="validationContext" name="mye">
        <label for="mye">
          <span class="text-danger">*</span>
          {{ $t("mye") }}
        </label>
        <v-select
          id="mye"
          v-model="blankRationData.MYE"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="[
            { label: $t('flat'), value: 1 },
            { label: $t('steep'), value: 2 },
          ]"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- shy -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="shy"
      >
        <label for="shy">
          <span class="text-danger">*</span>
          {{ $t("shy") }}
        </label>

        <b-form-input
          id="shy"
          v-model="blankRationData.SHY"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
    <b-form-group
      v-if="showField(5)"
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('efficiencyInfo')"
    >
      <!-- sv -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="sv"
      >
        <label for="sv">
          <span class="text-danger">*</span>
          {{ $t("sv") }}
        </label>
        <b-form-input
          id="sv"
          v-model="blankRationData.SV"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- sy -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="sy"
      >
        <label for="sy">
          <span class="text-danger">*</span>
          {{ $t("sy") }}
        </label>
        <b-form-input
          id="sy"
          v-model="blankRationData.SY"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- sp -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="sp"
      >
        <label for="sp">
          <span class="text-danger">*</span>
          {{ $t("sp") }}
        </label>
        <b-form-input
          id="sp"
          v-model="blankRationData.SP"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
      <!-- sl -->
      <validation-provider
        v-if="showField(5)"
        #default="validationContext"
        name="sl"
      >
        <label for="sl">
          <span class="text-danger">*</span>
          {{ $t("sl") }}
        </label>
        <b-form-input
          id="sl"
          v-model="blankRationData.SL"
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          type="number"
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="environmental-info"
      class="p-1 border d-flex flex-sm-row flex-md-col"
      :label="$t('roughage') + ' / ' + $t('grain') + ' ' + $t('rate') + ' (%)'"
    >
      <validation-provider #default="validationContext" name="minkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('minKaba')"
          label-for="minkaba"
        >
          <b-form-input
            id="sl"
            v-model="blankRationData.kabayemorani"
            :state="
              formValidation(resetblankration).getValidationState(
                validationContext
              )
            "
            trim
            type="number"
            placeholder=""
          />
        </b-form-group>
      </validation-provider>

      <validation-provider #default="validationContext" name="maxkaba">
        <b-form-group
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          :label="$t('maxKaba')"
          label-for="maxkaba"
        >
          <b-form-input
            id="maxkaba"
            v-model="blankRationData.kabayemoranimax"
            :state="
              formValidation(resetblankration).getValidationState(
                validationContext
              )
            "
            trim
            type="number"
            max="100"
            placeholder=""
          />
        </b-form-group>
      </validation-provider>
    </b-form-group>
  </b-form>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import dayjs from "dayjs";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BForm,
    BFormInput,
    vSelect,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    formValidation: {
      type: Function,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      oneToFive: Array.from(Array(5), (_, i) => i + 1).map((val) => {
        return { label: val, value: val };
      }),
      oneToFiveFloatHalf: Array.from(Array(11), (_, i) => i + 1).map((val) => {
        return { label: val / 2 - 0.5, value: val / 2 - 0.5 };
      }),
      oneToHundred: Array.from(Array(100), (_, i) => i + 1).map((val) => {
        return { label: `${val} / ${100 - val}`, value: val };
      }),
      animalStatuses: [
        { label: this.$t("heifer"), value: 3 },
        { label: this.$t("firstPregnancy"), value: 4 },
        { label: this.$t("inLactation"), value: 5 },
        { label: this.$t("inDry"), value: 6 },
      ],
      blankRationData: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        companyid: getUserData().companyid,
        kabayemorani: "",
        kabayemoranimax: "",
        RasyonAdi: "",
        HayvanTipi: "",
        HayvanTipiid: 3,
        irk: "",
        irkid: "",
        CA: "", // Canlı Ağırlığı
        GCAA: "", // Günlük Canlı Ağırlık Artışı
        BDA: "", // Buzağı Doğum Ağırlığı
        KGS: "", // Kaç Gündür Sütü Sağılıyor
        KGG: "", // Kaç Günlük Gebe
        sicaklik: "",
        yemtoplami: 0,
        yogunhammaddelertoplami: 0,
        RasyonFiyati: 0,
        SY: "", // Süt Yağı
        SP: "", // Süt Protein
        SV: 0, // Süt Verimi
        SL: "", // Süt Laktozu
        LS: "", // Laktasyon Sayısı
        MU: "", // Mera Uzaklığı
        MYE: 1, // Mera Yolu Eğimi Düz = 0, Eğimli = 1
        DSV: "", //
        MGGS: "", // Meraya Gidiş-Geliş Sayısı
        SHY: "", // Sağımhaneye Yürüyüş Mesafesi
        BCS: "", // Body Condition Score
        KatyonAnyonDegeri: 0,
        ilkdogum: 0,
      },
      resetblankration: {
        plantid: this.$store.state.app.selectedPlantId,
        tarih: dayjs().format("YYYY-MM-DD"),
        companyid: getUserData().companyid,
        kabayemorani: "",
        kabayemoranimax: "",
        RasyonAdi: "",
        HayvanTipi: "",
        irk: "",
        irkid: "",
        CA: "", // Canlı Ağırlığı
        GCAA: "", // Günlük Canlı Ağırlık Artışı
        BDA: "", // Buzağı Doğum Ağırlığı
        KGS: "", // Kaç Gündür Sütü Sağılıyor
        KGG: "", // Kaç Günlük Gebe
        sicaklik: "",
        yemtoplami: 0,
        yogunhammaddelertoplami: 0,
        RasyonFiyati: 0,
        SY: "", // Süt Yağı
        SP: "", // Süt Protein
        SV: 0, // Süt Verimi
        SL: "", // Süt Laktozu
        LS: "", // Laktasyon Sayısı
        MU: "", // Mera Uzaklığı
        MYE: 1, // Mera Yolu Eğimi Düz = 0, Eğimli = 1
        DSV: "", //
        MGGS: "", // Meraya Gidiş-Geliş Sayısı
        SHY: "", // Sağımhaneye Yürüyüş Mesafesi
        BCS: "", // Body Condition Score
        KatyonAnyonDegeri: 0,
        ilkdogum: 0,
      },
    };
  },
  computed: {
    realCa() {
      const { SV, CA, GCAA } = this.blankRationData;
      const calculation = ((SV * GCAA) / 1000 + CA * 2) / 2;
      return calculation;
    },
    status() {
      return this.blankRationData.HayvanTipiid;
    },
  },
  watch: {
    status: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          var name = this.blankRationData.RasyonAdi;
          var irk = this.blankRationData.irk;
          var irkid = this.blankRationData.irkid;
          var ca = this.blankRationData.CA;
          var gcaa = this.blankRationData.GCAA;
          var kabayemorani = this.blankRationData.kabayemorani;
          var kabayemoranimax = this.blankRationData.kabayemoranimax;
          this.blankRationData = {
            ...this.resetblankration,
            RasyonAdi: name,
            irk: irk,
            irkid: irkid,
            HayvanTipiid: newVal,
            CA: ca,
            GCAA: gcaa,
            kabayemorani,
            kabayemoranimax,
          };
        }
      },
      deep: true,
    },
    blankRationData: {
      handler: function (newVal, oldVal) {
        const newmin = Number(this.blankRationData.kabayemorani);
        const newmax = Number(this.blankRationData.kabayemoranimax);

        if (newmin > newmax) {
          this.blankRationData.kabayemoranimax = newmin;
        }
        if (newmin >= 100) {
          this.blankRationData.kabayemorani = 100;
        }
        if (newmax >= 100) {
          this.blankRationData.kabayemoranimax = "100";
        }
        if (newmin < 0) {
          this.blankRationData.kabayemorani = 0;
        }
        if (newmax < 0) {
          this.blankRationData.kabayemoranimax = 0;
        }
      },
      deep: true,
    },
  },

  methods: {
    onSubmit() {
      Object.keys(this.blankRationData).forEach((key) => {
        if (this.blankRationData[key] === "") {
          this.blankRationData[key] = 0;
        }
      });
      this.$emit("onSubmit", this.blankRationData);
    },
    showField(...ids) {
      return ids.includes(this.status);
    },
  },
};
</script>

<style lang="scss">
.form-group > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  margin-bottom: 0.5rem;
  width: 100%;
  gap: 14px;
  flex-wrap: wrap;
  span {
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 40%;
      max-width: 50%;
    }
  }
}

#dairy-add-form {
  .form-group {
    margin-bottom: 0.5rem;
    border-radius: 6px;
    legend {
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
