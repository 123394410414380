<template>
  <b-form
    class="p-2"
    @submit.prevent="handleSubmit(onSubmit)"
    @reset.prevent="resetForm"
  >
    <!-- rationname -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="rationname"
    >
      <b-form-group :label="$t('rationName')" label-for="rationname">
        <b-form-input
          id="rationname"
          v-model="blankRationData.rasyonadi"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- breed -->
    <validation-provider
      #default="validationContext"
      rules="required"
      name="breed"
    >
      <b-form-group
        :state="
          formValidation(resetblankration).getValidationState(validationContext)
        "
        :label="$t('type')"
        label-for="breed"
      >
        <v-select
          v-model="blankRationData.breed"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="Types"
          class="w-100"
          :reduce="(val) => val.value"
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="ca"
    >
      <b-form-group :label="$t('ca')" label-for="ca">
        <b-form-input
          id="ca"
          v-model="blankRationData.ca"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="ga"
    >
      <b-form-group :label="$t('ga')" label-for="ga">
        <b-form-input
          id="ga"
          v-model="blankRationData.ga"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="conditionscore"
    >
      <b-form-group
        :state="
          formValidation(resetblankration).getValidationState(validationContext)
        "
        :label="$t('conditionScore')"
        label-for="conditionscore"
      >
        <v-select
          id="conditionscore"
          v-model="blankRationData.conditionscore"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="conditionscore"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="karkas"
    >
      <b-form-group :label="$t('carcassratio')" label-for="karkas">
        <b-form-input
          id="karkas"
          v-model="blankRationData.karkas"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="temperature"
    >
      <b-form-group :label="$t('temperature')" label-for="temperature">
        <b-form-input
          id="temperature"
          v-model="blankRationData.temperature"
          autofocus
          :state="
            formValidation(resetblankration).getValidationState(
              validationContext
            )
          "
          type="number"
          step="any"
          trim
          placeholder=""
        />

        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="minkaba"
    >
      <b-form-group
        :state="
          formValidation(resetblankration).getValidationState(validationContext)
        "
        :label="$t('minKaba')"
        label-for="minkaba"
      >
        <v-select
          id="minkaba"
          v-model="blankRationData.minkaba"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="oneToHundred"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="validationContext"
      rules="required"
      name="maxkaba"
    >
      <b-form-group
        :state="
          formValidation(resetblankration).getValidationState(validationContext)
        "
        :label="$t('maxKaba')"
        label-for="maxkaba"
      >
        <v-select
          id="maxkaba"
          v-model="blankRationData.maxkaba"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="oneToHundred"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-form-group>
    </validation-provider>

    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
      >
        {{ $t("add") }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="outline-secondary"
        @click="hide"
      >
        {{ $t("cancel") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    vSelect,
    ValidationProvider,
    BFormGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
    formValidation: {
      type: Function,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      conditionscore: [],
      oneToFive: Array.from(Array(5), (_, i) => i + 1).map((val) => {
        return { label: val, value: val };
      }),
      oneToHundred: Array.from(Array(100), (_, i) => i + 1).map((val) => {
        return { label: `${val} / ${100 - val}`, value: val };
      }),
      Types: [
        { label: "Kombine", value: 1 },
        { label: "Besi", value: 2 },
      ],

      blankRationData: {
        plantid: this.$store.state.app.selectedPlantId,
        rationname: "",
        breed: "",
        tip: "",
        fiyat: 0,
        model: "",
        ca: "",
        ga: "",
        karkas: "",
        conditionscore: "",
        temperature: "",
        minkaba: "",
        maxkaba: "",
      },
      resetblankration: {
        plantid: this.$store.state.app.selectedPlantId,
        rationname: "",
        breed: "",
        tip: "",
        fiyat: 0,
        model: "",
        ca: "",
        ga: "",
        karkas: "",
        conditionscore: "",
        temperature: "",
        minkaba: "",
        maxkaba: "",
      },
      numberOfGroups: [
        { label: "singleFed", value: 0 },
        { label: "group1", value: 1 },
        { label: "group2", value: 2 },
        { label: "group3", value: 3 },
        { label: "group4", value: 4 },
      ],
    };
  },
  mounted() {
    for (var i = 0; i <= 5; i += 0.25) {
      this.conditionscore.push({ label: i, value: i });
    }
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.blankRationData);
    },
  },
};
</script>
